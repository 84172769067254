import * as React from "react";

interface SchoolIconProps {
  width: string
  height: string
  open?: boolean
  color?: string
}

const DoorOpen: React.SFC<SchoolIconProps> = (props) => {
  const open = (
    <path d="M0,21.479v451.5h34v-82.953h11.536v82.456l141.086-30.854V98.84L45.536,67.985v53.447H34V55.479h426.459v65.953h-12.535   V67.985L306.838,98.84v342.788l141.086,30.854v-82.456h12.535v82.953h34V21.479H0z M162.971,248.046   c5.036,0,9.12,4.789,9.12,10.697s-4.084,10.697-9.12,10.697c-5.036,0-9.121-4.789-9.121-10.697S157.935,248.046,162.971,248.046z    M45.536,168.453v174.55H34v-174.55H45.536z M330.486,269.441c-5.035,0-9.121-4.789-9.121-10.698   c0-5.908,4.086-10.695,9.121-10.695s9.121,4.787,9.121,10.695C339.607,264.652,335.521,269.441,330.486,269.441z M460.457,343.003   h-12.535v-174.55h12.535V343.003z"/>
  )
  const closed = (
    <path d="M0,21.479v451.5h34v-82.953h9.99v82.953h196.598V65.78H43.99v55.652H34V55.479h426.459v65.953h-9.988V65.78H253.873   v407.199h196.598v-82.953h9.988v82.953h34v-451.5H0z M198.105,243.021c7.018,0,12.709,5.688,12.709,12.708   s-5.691,12.708-12.709,12.708s-12.709-5.688-12.709-12.708S191.087,243.021,198.105,243.021z M43.99,168.453v174.55H34v-174.55   H43.99z M296.355,268.438c-7.019,0-12.709-5.688-12.709-12.708s5.69-12.708,12.709-12.708c7.018,0,12.709,5.688,12.709,12.708   S303.373,268.438,296.355,268.438z M450.471,343.003v-174.55h9.986v174.55H450.471z"/>
  )
  
  return (
    <svg 
      width={props.width} 
      height={props.height} 
      fill={props.color} 
      xmlns="http://www.w3.org/2000/svg" 
      x="0px" 
      y="0px"
      viewBox="0 -50 495 545"
      strokeWidth="2"
    >
      {props.open && props.open === true ? open : closed}
    </svg>
  );
}

export default DoorOpen;
